<template>
  <div class="privacy_policy">
    <h1>Privacy Policy</h1>
    <h3>Last Updated: {{ lastUpdated }}</h3>
    <div>
      <span v-html="introduction"></span>
    </div>
    <div>
        <h4>Collection of your information</h4>
        <p>{{ analyticsDesc }}</p>
        <p>{{ adsDesc }}</p>
        <p>{{ feedbackDesc }}</p>
    </div>
    <div>
        <h4>Use of your information</h4>
        <p>{{ useOfInfoDesc }}</p>
    </div>
    <div>
        <h4>Contact Us</h4>
        <p>{{ contactDesc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  data: function () {
    return {
      lastUpdated: "October 22, 2020",
      introduction:
        "<div>" +
        "Buddy Home Workout (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION." +
        "<br>" +
        "<br>" +
        "We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted." +
        "<br>" +
        "<br>" +
        "This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party." +
        "</div>",
      analyticsDesc:
        "Analytics  -  We use third party analytics tool, Firebase Analytics, to find out insights and measure traffic. Firebase Analytics collects information such as how often users visit our services, what pages they visit when they do so, and what other pages they use prior to coming to our services. We use the information get from Firebase Analytics only to improve our services.",
      adsDesc:
        "Ads  -  Our app may use some providers to show ads. These Ads providers can use cookies to identify your device, then show ads that are relevant. We do not share any user's data with any other parties.",
      feedbackDesc:
        "Feedback  -  The only situation we may get access to your personal identifiable information is when you decide to email us for feedback. The information is limited to your email, your device model, operating system and your message only.",
      useOfInfoDesc:
        "In above situation, we guarantee that your information will only be used for contacting you and improving our services. Moreover, we may use the analytics data in order to send you push notifications and in app messages aiming to increase your motivation for working out. We will never use such information (e.g. your name and email address) for any other purposes, such as to further market our products, or to disclose your personal information to a third party for commercial gains.",
      contactDesc:
        "If you have questions or comments about this Privacy Policy, please contact us at hi@buddyhomeworkout.app",
    };
  },
};
</script>

<style scoped>
.privacy_policy {
  text-align: left;
  padding: 0px 25px 25px 25px;
}
</style>
